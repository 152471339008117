import { Box } from '@mui/material'

import styled from 'styled-components'

export const WrapperForm = styled(Box)`
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding-right: 32px;
  padding-bottom: 64px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    padding-right: 0px;
    padding-bottom: 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #dadada;

    border: 4px solid #dadada;

    border-radius: 99999px;
  }

  .form-footer {
    @media (max-width: 1024px) {
      position: fixed !important;
      bottom: 0;
      left: 0px !important;
      right: 0px !important;
      flex-direction: column-reverse;
      padding: 24px 16px !important;

      div {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }

  .scrolled-area {
    margin-bottom: 16px;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    overflow: -o-scrollbars-none;
    -webkit-overflow-scrolling: touch;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    h1 {
      color: #1d1d1d;
      font-size: 40px;
      font-weight: 500;
      line-height: 130%;
      margin-bottom: 16px;
    }

    button {
      width: 100%;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 450;
      line-height: 18px;
      margin-bottom: 16px;
      height: 44px;

      span {
        color: var(--Gold, #bb945c);
        font-size: 12px;
        font-weight: 450;
        line-height: 18px;
        margin-left: 5px;
      }

      svg {
        margin: 0;
        width: 24px;
        height: 24px;

        path {
          fill: transparent;
        }
      }
    }

    .divider {
      position: relative;
      display: flex;
      align-items: center;
      color: #666;
      font-size: 12px;
      font-weight: 450;
      line-height: 18px;
      width: 100%;
      gap: 16px;
      margin-bottom: 16px;

      &:before,
      &:after {
        content: '';
        display: block;
        height: 1px;
        background: #d5dadf;
        flex: 1 0 0;
      }
    }

    h3 {
      color: #1d1d1d;
      font-size: 24px;
      font-weight: 500;
      line-height: 120%;
      margin-bottom: 8px;

      @media (max-width: 1024px) {
        text-align: center;
        font-size: 22px;
      }
    }

    .billing-card {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 16px;
      background: #f5f5f7;
      margin-bottom: 16px;

      &-label {
        font-size: 14px;
        margin-bottom: 8px;
        line-height: 150%;
        color: #6f6f6f;
        font-weight: 450;

        &-error {
          color: #c8102e;
        }
      }

      &-line {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        margin-bottom: 16px;

        @media (max-width: 1024px) {
          grid-template-columns: repeat(1, 1fr);
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        & .billing-card-line {
          gap: 8px;
          margin-bottom: 0;
        }

        .sc-guDLey,
        .form-item {
          width: 100%;
        }
      }
      .MuiInputBase-root {
        background: white;
      }
    }

    .checkbox-button {
      width: 100% !important;

      display: flex;
      border-radius: 8px;
      position: static;
      margin: 16px 0 10px 0;
      border-radius: 8px;
      border: 1px solid #d5dadf;
      padding: 8px 16px;

      &.checked {
        border: 1px solid #bb945c;
      }

      .checkbox-label {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        a {
          color: #bb945c;
        }
      }
    }
  }

  .form {
    &-col {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;

      @media (max-width: 1024px) {
        width: 100%;
      }

      &-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
      }
    }

    &-item {
      flex-basis: calc(50% - 0.8rem);

      @media (max-width: 768px) {
        flex-basis: 100%;
      }

      &-full {
        flex-basis: 100%;
      }
    }
  }

  .form-footer {
    display: flex;
    gap: 16px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 64px;

    button {
      height: 52px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    button[type='submit'] {
      &:disabled {
        opacity: 0.3;
        color: white;
        cursor: not-allowed;
        pointer-events: auto;
        position: relative;
        z-index: 1;
      }
    }
  }

  .form-item {
    position: relative;

    .MuiInputBase-root-jxHnQA {
      background: white;
    }
  }

  @media (max-width: 1182px) {
    .billing-card-line {
      flex-direction: column;
    }
  }

  @media (max-width: 1024px) {
    .billing-card-line {
      flex-direction: row;
    }
    .form-footer {
      position: static;
      padding: 8px 0 42px;
    }
  }

  @media (max-width: 768px) {
    .billing-card-line {
      flex-direction: column;
    }
  }

  .accordion {
    margin-top: 16px;
    border: 1px solid #d5dadf;
    border-radius: 16px;
    padding: 0;

    & .MuiCollapse-root {
      border-top: 1px solid #d5dadf;
    }
  }

  .accordion-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;

    &.Mui-expanded {
      height: 48px;
      min-height: 48px;
    }
  }

  .accordion-title {
    padding-left: 12px;
    color: #2d4960;
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;
  }

  .accordion-details {
    padding: 0;
  }

  .bordered-discount {
    border: 1px solid #d5dadf;
    border-radius: 8px;
    padding: 12px 16px;
  }
`
