import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useController, useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery
} from '@mui/material'

import ArrowRight from '../../assets/icons/ArrowRight'
import CalendarIcon from '../../assets/icons/CalendarIcon'
import CheckedIcon from '../../assets/icons/CheckedIcon'
import CircleIcon from '../../assets/icons/CircleIcon'
import InfoIcon from '../../assets/icons/InfoIcon'
import ListIcon from '../../assets/icons/ListIcon'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import CustomButton from '../../components/Button'
import DateModal from '../../components/DateModal'
import Discount from '../../components/Discount'
import Form from '../../components/Form'
import List from '../../components/List'
import RadioButtonGroup from '../../components/RadioButtonGroup'
import Select from '../../components/Select'
import Summary from '../../components/Summary'
import Input from '../../components/TextField'
import CustomTooltip from '../../components/Tooltip'
import TooltipModal from '../../components/TooltipModal'
import {
  addPackage,
  addProgramDate,
  addTransferDetails,
  createNewProductOrder,
  deleteProductOrder,
  getAgesByOrder,
  getEducationalModelsByProductOrder,
  getLocationsByOrder,
  getProgramDatesByProductOrder,
  getSubjectsByOrder,
  selectAge,
  selectEducationalModel,
  selectLocation,
  selectPreferredMonths,
  selectSubject,
  validateProductOrder
} from '../../store/enrolment/enrolment.actions'
import {
  selectAgesByOrder,
  selectEducationalModels,
  selectIsDeposit,
  selectIsNewProgramAdded,
  selectLocationsByOrder,
  selectMainMainOrderId,
  selectOrder,
  selectProductOrders,
  selectSubjectsByOrder
} from '../../store/enrolment/enrolment.selectors'
import { setActiveProductOrderId, setIsDeposit, setisNewProgramAdded } from '../../store/enrolment/enrolment.slice'
import EducationProgrammeBox from '../StartEducationalPathwayScreen/components/EducationProgrammeBox'
import { newProgrammeOptions } from './constants'
import { InnerWrapper, Wrapper } from './styled'

import { useDispatch, useSelector } from 'react-redux'

let toastId = null

const AddAnotherProgramScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')
  const ref = useRef(null)

  const order = useSelector(selectOrder)
  const orderId = useSelector(selectMainMainOrderId)
  const productOrders = useSelector(selectProductOrders)
  const locationsByOrder = useSelector(selectLocationsByOrder)
  const agesByOrder = useSelector(selectAgesByOrder)
  const subjectsByOrder = useSelector(selectSubjectsByOrder)
  const educationalModelsByOrder = useSelector(selectEducationalModels)
  const isNewProgramAdded = useSelector(selectIsNewProgramAdded)
  const isDeposit = useSelector(selectIsDeposit)

  const [step, setStep] = useState(0)
  const [classificationValue, setClassificationValue] = useState(null)
  const [dateValue, setDateValue] = useState(null)
  const [transferOptions, setTransferOptions] = useState([])
  const [hasNewProgramme, setHasNewProgramme] = useState(null)
  const [location, setLocation] = useState(null)
  const [age, setAge] = useState(null)
  const [educationalModel, setEducationalModel] = useState([])
  const [subject, setSubject] = useState(null)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [paymentOption, setPaymentOption] = useState(null)
  const [classifications, setClassifications] = useState([])
  const [dates, setDates] = useState([])
  const [months, setMonths] = useState(null)
  const [isOpenDateModal, setIsOpenDateModal] = useState(false)
  const [transferOptionsData, setTransferOptionsData] = useState([])
  const [activeProductOrder, setActiveProductOrder] = useState(null)
  const [selectWidth, setSelectWidth] = useState(null)
  const [isOpenTooltipModal, setIsOpenTooltipModal] = useState(false)

  const productId = localStorage.getItem('productId')

  const handleOpenDateModal = () => setIsOpenDateModal(isOpenDateModal => !isOpenDateModal)
  const handleOpenTooltipModal = () => setIsOpenTooltipModal(isOpenTooltipModal => !isOpenTooltipModal)

  useEffect(() => {
    const classifications = activeProductOrder?.product?.packages?.map(item => {
      const updatedTextArray = item?.listOfFeatures.map((text, index) => {
        return <span key={index} dangerouslySetInnerHTML={{ __html: text }} />
      })

      return {
        value: item.id,
        label: item.classification.title,
        isDiscount: !!item.discount,
        discount: item.discount,
        tooltipWidth: 455,
        info: item.packageTitle,
        duration: item.description,
        discountPrice: item.discountPrice,
        price: item.price,
        tooltip: (
          <List
            header={item.featuresTitle}
            options={updatedTextArray.map(item => ({
              component: <Typography className='list-text'>{item}</Typography>
            }))}
          />
        )
      }
    })

    // const dates = activeProductOrder?.product?.taxonomy?.programDates?.map(item => {
    //   return {
    //     label: item.title,
    //     value: item.id
    //   }
    // })

    const paymentOptions = order?.totalDepositAmount
      ? [
          { label: 'Pay total', price: order?.totalOrderAmount, value: 0 },
          {
            label: 'Pay deposit',
            price: order?.totalDepositAmount,
            value: 1,
            tooltip: (
              <>
                <Typography className='tooltip-header' component='h3'>
                  Deposit options
                </Typography>

                <Typography className='tooltip-text'>
                  <span style={{ fontWeight: '700' }} className='tooltip-span'>
                    £995
                  </span>{' '}
                  deposit for all in-person and online research programmes <br />
                  <span style={{ fontWeight: '700' }} className='tooltip-span'>
                    £695
                  </span>{' '}
                  deposit for online insight programmes (excluding intensive options)
                  <br />
                  <br />
                  Remaining balance is due by 12 weeks prior to programme as highlighted in terms and conditions.
                </Typography>
              </>
            )
          }
        ]
      : [{ label: 'Pay total', price: order?.totalOrderAmount, value: 0 }]

    const transferOptionsData = [
      {
        label: 'Pick-up + Return',
        value: 'Pick-up + Return',
        transferFrom: true,
        transferTo: true,
        mostPopular: true,
        price:
          activeProductOrder?.location?.airportTransferPrice && activeProductOrder?.location?.airportTransferPrice * 2
      },
      {
        label: 'Pick-up only',
        value: 'Pick-up only',
        transferFrom: true,
        transferTo: false,
        mostPopular: false,
        price: activeProductOrder?.location?.airportTransferPrice
      },
      {
        label: 'Return only',
        value: 'Return only',
        transferFrom: false,
        transferTo: true,
        mostPopular: false,
        price: activeProductOrder?.location?.airportTransferPrice
      },
      {
        label: 'No (maybe later)',
        value: 'No (maybe later)',
        transferFrom: false,
        transferTo: false,
        mostPopular: false
      }
    ]

    setClassifications(classifications)
    // setDates(dates)
    setPaymentOptions(paymentOptions)
    setTransferOptionsData(transferOptionsData)
  }, [activeProductOrder, dispatch, order?.totalDepositAmount, order?.totalOrderAmount, productOrders])

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      hasNewProgramme: null,
      location: null,
      age: null,
      subject: null,
      dates: null,
      transferOptions: null,
      classification: null,
      paymentOption: null,
      educationalModel: null,
      months: []
    }
  })

  const { field: hasNewProgrammeField } = useController({
    name: 'hasNewProgramme',
    control: control
  })

  const { field: locationField } = useController({
    name: 'location',
    control: control
  })

  const { field: ageField } = useController({
    name: 'age',
    control: control
  })

  const { field: subjectField } = useController({
    name: 'subject',
    control: control
  })

  const { field: datesField } = useController({
    name: 'dates',
    control: control
  })

  const { field: transferOptionsField } = useController({
    name: 'transferOptions',
    control: control
  })

  const { field: classificationField } = useController({
    name: 'classification',
    control: control
  })

  const { field: paymentOptionField } = useController({
    name: 'paymentOption',
    control: control
  })

  const { field: educationalModelField } = useController({
    name: 'educationalModel',
    control: control
  })

  const { field: monthsField } = useController({
    name: 'months',
    control: control
  })

  const handleHasNewProgrammeChange = event => {
    setHasNewProgramme(event.target.value)
    hasNewProgrammeField.onChange(event.target.value)
    if (event.target.value === 'true') {
      setPaymentOption(null)
      dispatch(
        createNewProductOrder({
          orderId
        })
      )
        .then(({ payload }) => {
          const activeProductOrder = [...payload.productOrders].pop()
          setActiveProductOrder(activeProductOrder)
          dispatch(getLocationsByOrder({ productOrderId: activeProductOrder.id })).then(
            ({ payload: locationsPayload }) => {
              const currentLocation = locationsPayload.find(
                ({ id }) => id === payload.productOrders[payload.productOrders.length - 2]?.location?.id
              )

              if (currentLocation) {
                const locationId = currentLocation.id
                setLocation(locationId)
                locationField.onChange(locationId)
                dispatch(
                  selectLocation({
                    productOrderId: activeProductOrder.id,
                    body: JSON.stringify({ locationId: locationId }),
                    callback: () => {
                      if (step <= 2) {
                        setStep(2)
                      }
                    }
                  })
                )
                  .then(({ payload }) => {
                    const activeProductOrder = [...payload.productOrders].pop()
                    setActiveProductOrder(activeProductOrder)
                    if (activeProductOrder.location.title === 'Online') {
                      dispatch(getEducationalModelsByProductOrder({ productOrderId: activeProductOrder.id }))
                    } else {
                      dispatch(getAgesByOrder({ productOrderId: activeProductOrder.id }))
                    }
                    setClassificationValue(null)
                    setAge(null)
                    setDateValue(null)
                    setSubject(null)
                    setMonths(null)
                    setTransferOptions(transferOptionsData)
                    setStep(2)
                  })
                  .catch(() => {})
              }
            }
          )

          dispatch(setisNewProgramAdded(activeProductOrder.id))
        })
        .catch(() => {
          const activeProductOrder = [...productOrders].pop()
          setActiveProductOrder(activeProductOrder)
          dispatch(getLocationsByOrder({ productOrderId: activeProductOrder.id }))
          dispatch(setisNewProgramAdded(activeProductOrder.id))
        })
    } else {
      if (activeProductOrder?.id) {
        dispatch(
          deleteProductOrder({
            productOrderId: activeProductOrder.id
          })
        )
      }
    }
    setClassificationValue(null)
    setPaymentOption(null)
    setAge(null)
    setDateValue(null)
    setMonths(null)
    setSubject(null)
    setLocation(null)
    setTransferOptions(transferOptionsData)
    setStep(1)
  }

  const handleLocationChange = value => {
    const locationId = locationsByOrder.find(item => item.title === value).id
    setLocation(locationId)
    locationField.onChange(locationId)
    dispatch(
      selectLocation({
        productOrderId: activeProductOrder.id,
        body: JSON.stringify({ locationId: locationId }),
        callback: () => {
          if (step <= 2) {
            setStep(2)
          }
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = [...payload.productOrders].pop()
        setActiveProductOrder(activeProductOrder)
        if (activeProductOrder.location.title === 'Online') {
          dispatch(getEducationalModelsByProductOrder({ productOrderId: activeProductOrder.id }))
        } else {
          dispatch(getAgesByOrder({ productOrderId: activeProductOrder.id }))
        }
        setClassificationValue(null)
        setAge(null)
        setDateValue(null)
        setSubject(null)
        setMonths(null)
        setTransferOptions(transferOptionsData)
        setStep(2)
      })
      .catch(() => {})
  }

  const handleAgeChange = event => {
    setAge(event.target.value)
    ageField.onChange(event.target.value)
    dispatch(
      selectAge({
        productOrderId: activeProductOrder.id,
        body: JSON.stringify({ ageId: event.target.value }),
        callback: () => {
          if (step <= 3) {
            setStep(3)
          }
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = [...payload.productOrders].pop()
        setActiveProductOrder(activeProductOrder)
        dispatch(getSubjectsByOrder({ productOrderId: activeProductOrder.id }))
        setClassificationValue(null)
        setDateValue(null)
        setMonths(null)
        setSubject(null)
        setTransferOptions(transferOptionsData)
        setStep(3)
      })
      .catch(() => {})
  }

  const handleEducationalModelChange = value => {
    const educationalModelId = educationalModelsByOrder.find(
      item => item.title === value.props.children[0].props.children[0].props.children[1].props.children
    )?.id
    setEducationalModel(educationalModelId)
    educationalModelField.onChange(educationalModelId)

    dispatch(
      selectEducationalModel({
        productOrderId: activeProductOrder.id,
        body: JSON.stringify({ modelId: educationalModelId }),
        callback: () => {
          if (step <= 3) {
            setStep(3)
          }
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = [...payload.productOrders].pop()
        setActiveProductOrder(activeProductOrder)
        dispatch(getSubjectsByOrder({ productOrderId: activeProductOrder.id }))
        setClassificationValue(null)
        setDateValue(null)
        setSubject(null)
        setTransferOptions(transferOptionsData)
        setStep(3)
      })
      .catch(() => {})
  }

  const handleSubjectChange = value => {
    const subjectId = subjectsByOrder.find(item => item.title.replace(/&amp;/g, '&') === value).id
    setSubject(subjectId)
    subjectField.onChange(subjectId)
    dispatch(
      selectSubject({
        productOrderId: activeProductOrder.id,
        body: JSON.stringify({ subjectId: subjectId }),
        callback: () => {
          if (step <= 4) {
            setStep(4)
          }
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = [...payload.productOrders].pop()
        setActiveProductOrder(activeProductOrder)
      })
      .catch(() => {
        setSubject('')
        subjectField.onChange('')
      })
  }

  const handleClassificationValueChange = event => {
    setClassificationValue(event.target.value)
    classificationField.onChange(event.target.value)
    dispatch(
      addPackage({
        productOrderId: activeProductOrder.id,
        body: JSON.stringify({ packageId: event.target.value }),
        callback: () => {
          if (step <= 5) {
            setStep(5)
          }
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = [...payload.productOrders].pop()
        setActiveProductOrder(activeProductOrder)
        dispatch(getProgramDatesByProductOrder({ productOrderId: activeProductOrder.id }))
          .then(({ payload }) => {
            const dates = payload?.map(item => {
              return {
                label: item.title,
                value: item.id,
                isSoldOut: item.isSoldOut
              }
            })
            setDates(dates)
          })
          .catch(() => {})
      })
      .catch(() => {})
  }

  const handleDateValueChange = event => {
    setDateValue(event.target.value)
    datesField.onChange(event.target.value)
    dispatch(
      addProgramDate({
        productOrderId: activeProductOrder.id,
        body: JSON.stringify({ programDateId: event.target.value }),
        callback: () => {
          if (step <= 6) {
            setStep(6)
          }
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = [...payload.productOrders].pop()
        setActiveProductOrder(activeProductOrder)
      })
      .catch(() => {})
  }

  const handleTransferOptionsChange = event => {
    const option = transferOptionsData.find(item => item.value === event.target.value)
    setTransferOptions(event.target.value)
    transferOptionsField.onChange(event.target.value)
    dispatch(
      addTransferDetails({
        productOrderId: activeProductOrder.id,
        body: JSON.stringify({ transferTo: option.transferTo, transferFrom: option.transferFrom }),
        callback: () => {
          if (step <= 7) {
            setStep(7)
          }
        }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = [...payload.productOrders].pop()
        setActiveProductOrder(activeProductOrder)
      })
      .catch(() => {})
  }

  const handleMonthsSubmit = (currentYearMonths, nextYearMonths) => {
    const currentYearValues = currentYearMonths.filter(item => item.selected).map(item => item.value)
    const nextYearValues = nextYearMonths.filter(item => item.selected).map(item => item.value)
    const inputValues = [...currentYearValues, ...nextYearValues]
    const values = [
      ...currentYearValues.map(item => ({ month: item.slice(0, 3), year: +item.slice(4, 8) })),
      ...nextYearValues.map(item => ({ month: item.slice(0, 3), year: +item.slice(4, 8) }))
    ]
    setMonths(inputValues)
    monthsField.onChange(inputValues)
    setStep(6)
    handleOpenDateModal()
    dispatch(
      selectPreferredMonths({
        productOrderId: activeProductOrder.id,
        body: JSON.stringify({ preferredMonths: values })
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = [...payload.productOrders].pop()
        setActiveProductOrder(activeProductOrder)
      })
      .catch(() => {})
  }

  const handleEditClassification = productOrderId => {
    const activeProductOrder = productOrders.find(item => item.id === productOrderId)
    setActiveProductOrder(activeProductOrder)
    dispatch(setActiveProductOrderId(activeProductOrder.id))
    dispatch(getProgramDatesByProductOrder({ productOrderId }))

    if (activeProductOrder?.childProductOrder?.id) {
      dispatch(getSubjectsByOrder({ productOrderId: activeProductOrder?.childProductOrder?.id }))
    }
    navigate(`/start-educational-pathway?productId=${productId}`)
  }

  const onSubmit = data => {
    if (hasNewProgramme === 'false') {
      navigate(`/participant-details`)
    } else {
      setStep(0)
      setClassificationValue(null)
      setPaymentOption(null)
      setHasNewProgramme(null)
      setAge(null)
      setDateValue(null)
      setSubject(null)
      setMonths(null)
      setLocation(null)
      setTransferOptions(transferOptionsData)
      dispatch(setisNewProgramAdded(null))
      dispatch(
        validateProductOrder({
          productOrderId: activeProductOrder.id,
          callback: () => {
            setStep(4)
            toastId = toast.success(
              <Typography className='toast-text'>You successfully added your programme</Typography>,
              {
                style: {
                  minWidth: isSmallScreen ? '100%' : '652px',
                  backgroundColor: '#E8F7EE',
                  borderRadius: '4px',
                  padding: '8px 16px 8px 16px',
                  borderLeft: '2px solid #18AB56'
                }
              }
            )
          }
        })
      )
      setActiveProductOrder(null)
    }
  }

  useEffect(() => {
    return () => {
      if (toastId) {
        toast.dismiss(toastId)
      }
    }
  }, [])

  useEffect(() => {
    setSelectWidth(ref.current ? ref.current.offsetWidth : 0)
  }, [ref.current])

  return (
    <Wrapper>
      <Typography className='title' component='h1'>
        Start Your Educational <span className='title-gold'>Pathway</span>
      </Typography>
      {isSmallScreen && (
        <>
          <Accordion
            className='accordion'
            disableGutters
            elevation={0}
            sx={{
              '&.Mui-expanded:first-of-type': {
                marginTop: '16px'
              }
            }}
          >
            <AccordionSummary expandIcon={<SelectAngleIcon />} className='accordion-summary'>
              <ListIcon />
              <Typography className='accordion-title'>Summary</Typography>
            </AccordionSummary>
            <AccordionDetails className='accordion-details'>
              <Summary />
            </AccordionDetails>
          </Accordion>
          <Box sx={{ marginTop: '16px' }}>
            <Discount titleClassName='bordered-discount' secondVersion />
          </Box>
        </>
      )}
      <>
        <Box className='education-programme-box'>
          {productOrders.map(item => {
            if (item.id !== isNewProgramAdded) {
              return (
                <EducationProgrammeBox
                  key={item?.id}
                  onClick={() => handleEditClassification(item?.id)}
                  model={item?.package?.classification?.title}
                  educationModel={item?.package?.packageTitle}
                  age={item?.age?.title}
                  subject={item?.subject?.title}
                  date={item?.product?.dateDuration}
                  location={item?.location?.title}
                  childProductOrder={item?.childProductOrder}
                  packageDescription={item?.package?.description}
                  programDate={item?.programDate?.title}
                  programAge={item?.product?.taxonomy?.age?.title}
                  prefferedMonths={item?.preferredMonths}
                />
              )
            }
            return null
          })}
        </Box>
        <Box>
          <Typography variant='h2' className='education-subtitle'>
            Want to add another programme?
          </Typography>
          <Box className='education-text-wrapper' ref={ref}>
            <Typography className='education-text'>
              A popular option for students who wish to develop in a different subject and enhance their learning. Add
              another programme and save up to{' '}
              <Box sx={{ display: 'inline-block' }}>
                <span className='education-text-gold'>35% off</span>.*
                <CustomTooltip
                  title={
                    <Box>
                      <Typography className='education-discount-info'>
                        Discount amount is subject to programme.*
                      </Typography>

                      <Typography className='education-discount-info'>
                        Discount will be taken off the cheapest programme.
                      </Typography>

                      <Typography className='education-discount-info'>
                        Programme add-ons are only permitted for <b>one</b> student per enrolment.
                      </Typography>
                    </Box>
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        '& .education-discount': {
                          fontSize: '14px',
                          fontWeight: 450,
                          lineHeight: '21px',
                          color: '#1d1d1d'
                        },
                        '& .education-discount-info': {
                          fontSize: '14px',
                          fontWeight: 300,
                          lineHeight: '21px',
                          color: '#1d1d1d'
                        }
                      }
                    }
                  }}
                >
                  <IconButton className='radio-buttons-content-tooltip' onClick={handleOpenTooltipModal}>
                    <InfoIcon />
                  </IconButton>
                </CustomTooltip>
              </Box>
            </Typography>
          </Box>
          <InnerWrapper>
            <Form
              className='form'
              onSubmit={handleSubmit(onSubmit)}
              footer={
                isSmallScreen ? (
                  <>
                    <CustomButton isLight onClick={() => navigate(-1)}>
                      Back
                    </CustomButton>

                    <CustomButton
                      type='submit'
                      disabled={hasNewProgramme === null || hasNewProgramme === 'true'}
                      className='form-footer-button'
                    >
                      Next <ArrowRight />
                    </CustomButton>
                  </>
                ) : (
                  <Box className='form-footer-buttons'>
                    <CustomButton
                      type='submit'
                      disabled={hasNewProgramme === null || hasNewProgramme === 'true'}
                      className='form-footer-button'
                    >
                      Next to Student Details <ArrowRight />
                    </CustomButton>
                  </Box>
                )
              }
            >
              <RadioButtonGroup
                isSmall
                name='hasNewProgramme'
                value={hasNewProgramme}
                groupName='newProgramme'
                options={newProgrammeOptions}
                onChange={handleHasNewProgrammeChange}
              />
              {hasNewProgramme === 'true' && (
                <>
                  {step >= 1 && !!locationsByOrder?.length && (
                    <Select
                      onChange={handleLocationChange}
                      label='Select your preferred location'
                      options={locationsByOrder?.map(item => item.title) || []}
                      initialValue=''
                      value={locationsByOrder?.find(item => item.id === location)?.title || ''}
                      name='location'
                      placeholder='Choose location'
                    />
                  )}
                  {step >= 2 && locationsByOrder?.find(item => item.id === location).title !== 'Online' && (
                    <RadioButtonGroup
                      isSmall
                      isColumn={isSmallScreen}
                      label='Select age'
                      name='age'
                      value={age}
                      groupName='age'
                      options={agesByOrder?.map(item => ({ label: item.title, value: item.id })) || []}
                      onChange={handleAgeChange}
                    />
                  )}
                  {step >= 2 && locationsByOrder?.find(item => item.id === location).title === 'Online' && (
                    <Select
                      onChange={handleEducationalModelChange}
                      label='Choose online educational model'
                      isEducationModel
                      options={
                        educationalModelsByOrder?.map(item => (
                          <Box
                            sx={{
                              borderRadius: '16px',
                              padding: '16px',
                              backgroundColor: '#F5F5F7',
                              width: '100%'
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <Box sx={{ paddingTop: '6px' }}>
                                  {item.id === educationalModel ? <CheckedIcon /> : <CircleIcon />}
                                </Box>
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    minHeight: 'auto',
                                    color: '#181C2A',
                                    lineHeight: '21px',
                                    '@media (max-width: 1024px)': {
                                      fontSize: '14px',
                                      lineHeight: '18px'
                                    }
                                  }}
                                >
                                  {item.title}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: '82px',
                                  height: '32px',
                                  padding: '6px 8px 6px 8px',
                                  borderRadius: '24px',
                                  backgroundColor: '#FFFFFF'
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    fontWeight: 450,
                                    minHeight: 'auto',
                                    color: '#2D4960',
                                    '@media (max-width: 1024px)': {
                                      fontSize: '12px',
                                      lineHeight: '16px'
                                    }
                                  }}
                                >
                                  Ages {item.minAge}-{item.maxAge}
                                </Typography>
                              </Box>
                            </Box>
                            {item?.description && (
                              <Box sx={{ marginTop: '8px', maxWidth: selectWidth - 46, marginLeft: '34px' }}>
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    fontWeight: 300,
                                    height: 'auto',
                                    color: '#6F6F6F',
                                    whiteSpace: 'wrap'
                                  }}
                                >
                                  {item?.description}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        )) ?? []
                      }
                      value={(function () {
                        const model = educationalModelsByOrder?.find(item => item.id === educationalModel)
                        if (model) {
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                              <Typography sx={{ fontSize: '16px', fontWeight: 450, minHeight: 'auto' }}>
                                {model.title}
                              </Typography>
                              <Box
                                sx={{ width: '4px', height: '4px', backgroundColor: '#BB945C', borderRadius: '50%' }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 450,
                                  paddingRight: '16px',
                                  minHeight: 'auto',
                                  color: '#6F6F6F'
                                }}
                              >
                                Ages {model.minAge}-{model.maxAge}
                              </Typography>
                            </Box>
                          )
                        }
                        return ''
                      })()}
                      name='educationalModel'
                      placeholder='Choose online educational model'
                    />
                  )}
                  {step >= 3 && (
                    <Select
                      onChange={handleSubjectChange}
                      label='Select available subject'
                      options={subjectsByOrder?.map(item => item.title.replace(/&amp;/g, '&')) ?? []}
                      value={subjectsByOrder?.find(item => item.id === subject)?.title.replace(/&amp;/g, '&') || ''}
                      name='subject'
                      placeholder='Select subject'
                    />
                  )}
                  {step >= 4 && (
                    <RadioButtonGroup
                      label='Select your programme option'
                      name='educationModel'
                      value={classificationValue}
                      groupName='classifications'
                      options={classifications ?? []}
                      onChange={handleClassificationValueChange}
                    />
                  )}
                  {step >= 5 && !!dates?.length && (
                    <RadioButtonGroup
                      disabled
                      isColumn={isSmallScreen}
                      isSmall
                      name='dates'
                      label='Select your preferred dates'
                      value={dateValue}
                      groupName='dates'
                      options={dates}
                      onChange={handleDateValueChange}
                    />
                  )}
                  {step >= 5 && !dates?.length && (
                    <Input
                      value={months}
                      onClick={handleOpenDateModal}
                      label={
                        <Typography className='calendar-label'>
                          Select your preferred months{' '}
                          <span className='calendar-label-grey'>(we`ll do our best to cater to it)</span>
                        </Typography>
                      }
                      name='months'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton aria-label='toggle password visibility' edge='end'>
                            <CalendarIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                  {step >= 6 &&
                    locationsByOrder?.find(item => item.id === location)?.title !== 'Online' &&
                    classifications?.find(item => item?.value === classificationValue)?.label?.toLowerCase() !==
                      'non - residential' &&
                    locationsByOrder?.find(item => item.id === location)?.airportTransferPrice && (
                      <RadioButtonGroup
                        isSmall
                        isColumn={isSmallScreen}
                        isMostPopular
                        name='transferOptions'
                        tooltipProps={{
                          tooltip: {
                            sx: {
                              '& .title': {
                                fontSize: '14px',
                                fontWeight: 450,
                                lineHeight: '21px',
                                color: '#1d1d1d'
                              },
                              '& .box': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: '8px'
                              },
                              '& .dot': {
                                height: '4px',
                                width: '4px',
                                borderRadius: '50%',
                                background: '#1d1d1d'
                              },
                              '& .item': {
                                fontSize: '14px',
                                fontWeight: 300,
                                lineHeight: '21px',
                                color: '#1d1d1d'
                              }
                            }
                          }
                        }}
                        tooltip={
                          <Box>
                            <Typography className='title'>
                              Airport transfers will be from the following destinations
                            </Typography>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>London Heathrow Airport</Typography>
                            </Box>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>Sydney Kingsford Smith Airport</Typography>
                            </Box>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>Toronto Pearson International Airport</Typography>
                            </Box>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>John F. Kennedy International Airport</Typography>
                            </Box>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>San Francisco International Airport </Typography>
                            </Box>
                            <Typography className='item'>
                              Transfer details will be confirmed closer to the flight time.
                            </Typography>
                          </Box>
                        }
                        label='Would you like support with airport transfer?'
                        value={transferOptions}
                        groupName='transfer'
                        options={transferOptionsData}
                        onChange={handleTransferOptionsChange}
                      />
                    )}
                  {locationsByOrder?.find(item => item.id === location)?.title !== 'Online' &&
                  classifications?.find(item => item?.value === classificationValue)?.label?.toLowerCase() !==
                    'non - residential' &&
                  locationsByOrder?.find(item => item.id === location)?.airportTransferPrice
                    ? step >= 7 && (
                        <Box className='button-add'>
                          <CustomButton type='submit'>Add programme</CustomButton>
                        </Box>
                      )
                    : step >= 6 && (
                        <Box className='button-add'>
                          <CustomButton type='submit'>Add programme</CustomButton>
                        </Box>
                      )}
                </>
              )}
              {/* {hasNewProgramme === 'false' && (
                <>
                  <RadioButtonGroup
                    isSmall
                    isColumn
                    name='paymentOption'
                    label='Choose payment option'
                    value={paymentOption}
                    groupName='paymentOption'
                    options={paymentOptions}
                    onChange={handlePaymentOptionChange}
                  />
                </>
              )} */}
            </Form>
            <TooltipModal
              open={isOpenTooltipModal}
              onClose={handleOpenTooltipModal}
              modalProps={{
                '& .education-discount': {
                  fontSize: '14px',
                  fontWeight: 450,
                  lineHeight: '21px',
                  color: '#1d1d1d'
                },
                '& .education-discount-info': {
                  fontSize: '14px',
                  fontWeight: 300,
                  lineHeight: '21px',
                  color: '#1d1d1d'
                }
              }}
            >
              <Box>
                <Typography className='education-discount-info'>Discount amount is subject to programme.*</Typography>

                <Typography className='education-discount-info'>
                  Discount will be taken off the cheapest programme.
                </Typography>

                <Typography className='education-discount-info'>
                  Programme add-ons are only permitted for <b>one</b> student per enrolment.
                </Typography>
              </Box>
            </TooltipModal>
            <DateModal open={isOpenDateModal} onClose={() => setIsOpenDateModal(false)} onSubmit={handleMonthsSubmit} />
          </InnerWrapper>
        </Box>
      </>
      <Toaster
        position='bottom-center'
        containerStyle={{
          bottom: '120px'
        }}
        reverseOrder={false}
        toastOptions={{
          className: 'toaster',
          duration: 1500
        }}
      />
    </Wrapper>
  )
}

export default AddAnotherProgramScreen
