import { locationsByCountry } from '../screens/AddAnotherProgramScreen/constants'

export function getSortedLocations(selectedCity, locations) {
  const selectedCountry = locationsByCountry
    .find(location => location.city === selectedCity?.toLowerCase())
    ?.country?.toLowerCase()

  return [...locations].sort((a, b) => {
    const aCountry = locationsByCountry
      .find(location => location.city === a?.title?.toLowerCase())
      ?.country?.toLowerCase()

    const bCountry = locationsByCountry
      .find(location => location.city === b?.title?.toLowerCase())
      ?.country?.toLowerCase()

    if (aCountry === selectedCountry && bCountry !== selectedCountry) {
      return -1
    }
    if (aCountry !== selectedCountry && bCountry === selectedCountry) {
      return 1
    }

    if (aCountry !== bCountry) return aCountry.localeCompare(bCountry)

    return a.title.localeCompare(b.title)
  })
}
